import React, { Component } from "react";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import "bootstrap-v4-rtl/dist/css/bootstrap-rtl.min.css";
//import $ from 'jquery';
//import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "./App.scss";

//import "./App.css";

import AuthService from "./services/auth.service";

import Login from "./components/login";
//import Register from "./components/register";
//import Public from "./components/public.component";
//import Profile from "./components/profile";
//import BoardSubscriber from "./components/board-subscriber.component";
import BoardExpert from "./components/board-expert.component";
//import BoardAdmin from "./components/board-admin.component";

//import Home from './components/home';
import QuestionList from './components/question-list';
import Question from './components/question';
import AnswerList from './components/answer-list'
/*
import Signup from './components/signup';
import Chat from './components/chat';
import ComingSoon from './components/coming-soon';
*/

//need secure route for question show page

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showExpertBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showExpertBoard: user.roles.includes("ROLE_EXPERT"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }
  }

  logOut() {
    AuthService.logout();
  }

  render() {
    const { currentUser, showExpertBoard } = this.state;

    return (
      <Router>
      <div>
        <nav className="navbar navbar-expand navbar-dark bg-dark">
            <img src="/images/logo.png" alt="Nafsii" id="logo" />

           <div className="navbar-nav mr-auto">
            {showExpertBoard && (
              <div>
              <li className="nav-item">
                <Link to={"/questions"} className="nav-link">
                  Questions
                </Link>
              </li>
              </div>
              )}
          </div>


          <div className="navbar-nav mr-auto">
          {showExpertBoard && (
            <div>
              <li className="nav-item">
                <Link to={"/answers"} className="nav-link">
                  Answered Questions
                </Link>
              </li>
              </div>
            )}
          </div>

          {currentUser ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <a href="/login" className="nav-link" onClick={this.logOut}>
                  LogOut
                </a>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  Login
                </Link>
              </li>
            </div>
          )}
        </nav>

        <div className="container mt-3">
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/answers" component={AnswerList} />
            <Route exact path={["/questions"]} component={QuestionList} />
            <Route path="/questions/:id" component={Question} />
            <Route path="/expert" component={BoardExpert} />
          </Switch>
        </div>
      </div>
      </Router>
    );
  }
}

/*Unused items

          <Route exact path={["/", "/home"]} component={Home} />
          <Route exact path="/public" component={Public} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/profile" component={Profile} />
          <Route path="/admin" component={BoardAdmin} />
          <Route path="/subscriber" component={BoardSubscriber} />


<li className="nav-item">
  <Link to={"/public"} className="nav-link">
    Public
  </Link>
</li>

<Link to={"/expert"} className="nav-link">
  Expert Board
</Link> 

{showAdminBoard && (
  <li className="nav-item">
    <Link to={"/admin"} className="nav-link">
      Admin Board
    </Link>
  </li>
)}

{currentUser && (
  <li className="nav-item">
    <Link to={"/subscriber"} className="nav-link">
      ubscriber
    </Link>
  </li>
)}

<Link to={"/profile"} className="nav-link">
  {currentUser.username}
</Link>


<li className="nav-item">
  <Link to={"/register"} className="nav-link">
    Sign Up
  </Link>
</li>

*/

    /* Previous navbar
    return (
      <Router>
        <div>
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <a href="/" className="navbar-brand">
              <img src="/images/logo.png" alt="Nafsii" id="logo" />
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/signup"} className="nav-link">
                  Signup
              </Link>
              </li>
              <li className="nav-item">
                <Link to={"/questions"} className="nav-link">
                  Questions
              </Link>
              </li>
            </ul>
            </div>
          </nav>
            <Switch>
              <Route exact path={["/questions"]} component={QuestionList} />
              <Route path="/questions/:id" component={Question} />
              <Route exact path="/" component={Home} />
              <Route path="/signup" component={Signup} />
              <Route path="/coming-soon" component={ComingSoon} />
              <Route path="/chat" component={Chat} />
            </Switch>
        </div>
      </Router>
    );
  }
}
*/

export default App;
