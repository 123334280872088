import React, { Component } from "react";
import QuestionDataService from "../services/question.service";
import { Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
//import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';

export default class QuestionList extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchQuestion = this.onChangeSearchQuestion.bind(this);
    this.retrieveQuestions = this.retrieveQuestions.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveQuestion = this.setActiveQuestion.bind(this);
    //this.removeAllQuestions = this.removeAllQuestions.bind(this);
    this.searchQuestion = this.searchQuestion.bind(this);

    this.state = {
      questions: [],
      currentQuestion: null,
      currentIndex: -1,
      searchQuestion: "",
      wanttodelete: false
    };
  }

  componentDidMount() {
    this.retrieveQuestions();
  }

  onChangeSearchQuestion(e) {
    const searchQuestion = e.target.value;

    this.setState({
      searchQuestion: searchQuestion
    });
  }

  retrieveQuestions() {
    QuestionDataService.getAll()
      .then(response => {
        this.setState({
          questions: response.data
        });
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  refreshList() {
    this.retrieveQuestions();
    this.setState({
      currentQuestion: null,
      currentIndex: -1
    });
  }

  setActiveQuestion(question, index) {
    this.setState({
      currentQuestion: question,
      currentIndex: index
    });
  }

  /*
  removeAllQuestions() {
    QuestionDataService.deleteAll()
      .then(response => {
        console.log(response.data);
        this.refreshList();
      })
      .catch(e => {
        console.log(e);
      });
  }
  */

  searchQuestion() {
    QuestionDataService.findByQuestion(this.state.searchQuestion)
      .then(response => {
        this.setState({
          questions: response.data
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  //have hidden delete all button as not needed
  //if currentQuestion.answered = true, show on list
  render() {
    const { questions, currentQuestion, currentIndex } = this.state;
    const nothingToAnswer = questions.every(question => question.answered);
    return (
      <div id="questions">
      <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h1>
            <span>Expert Area</span>
            <FormattedMessage 
              id="answer-list.h1"
              defaultMessage="List of Answered Questions"
              description="Answer List header"/>
          </h1>
          <h2>
            <Link
              to={"/questions"}
              className="btn btn-secondary"
            >
              View Unanswered Questions Here
            </Link>
          </h2>
        </div>
      </div>
      <div className="list row form">
        <div className="col-lg-4 questions">
        
          <ul className="list-group">
            {questions &&
              questions.map((question, index) => (
                <div key={index}>
                  {question.answered &&
                <li
                  className={
                    "list-group-item " +
                    (index === currentIndex ? "active" : "")
                  }
                  onClick={() => this.setActiveQuestion(question, index)}
                  key={index}
                >
                  <p>
                  {question.question}
                  </p> 
                </li>
                }
                </div>
              ))}
          </ul>
        </div>
        <div className="col-lg-8 question-area">
          {currentQuestion ? (
            <div>
              <div>
                <label>
                  <strong>Question:</strong>
                </label>{" "}
                <p>{currentQuestion.question}</p>
              </div>
              <div>
                <label>
                  <strong>Category:</strong>
                </label>{" "}
                {currentQuestion.categoryId === 1 && 
                  <p>Mental Health</p>
                }
                {currentQuestion.categoryId === 2 && 
                  <p>Sexual Health</p>
                }
                {currentQuestion.categoryId === 3 && 
                  <p>Covid-19</p>
                }
                {currentQuestion.categoryId === 4 && 
                  <p>Other</p>
                }
              </div>
              <div>
                <label>
                  <strong>Answer:</strong>
                </label>{" "}
                <p>{currentQuestion.answer}</p>
              </div>
            </div>
          ) : (
            <div>
            {questions && nothingToAnswer ? (
              <div>
                <p>There are currently no answered questions. Please check back later.</p>
              </div>
            ) :
            <div>
                <p>Please click on a question to view the answer.</p>
            </div>
            }
            </div>
          )}
        </div>
        </div>
      </div>
      </div>
    );
  }
}