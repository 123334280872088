import axios from 'axios';
import authHeader from './auth-header';

//Change to localhost:8080 for local or http://app.nafsii.com or live
const API_URL = 'http://app.nafsii.com/api/test/';

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getSubscriberBoard() {
    return axios.get(API_URL + 'subscriber', { headers: authHeader() });
  }

  getExpertBoard() {
    return axios.get(API_URL + 'expert', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }
}

export default new UserService();