import React, { Component } from "react";
import QuestionDataService from "../services/question.service";
import { Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
//import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';

export default class QuestionList extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchQuestion = this.onChangeSearchQuestion.bind(this);
    this.retrieveQuestions = this.retrieveQuestions.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveQuestion = this.setActiveQuestion.bind(this);
    //this.removeAllQuestions = this.removeAllQuestions.bind(this);
    this.searchQuestion = this.searchQuestion.bind(this);

    this.state = {
      questions: [],
      currentQuestion: null,
      currentIndex: -1,
      searchQuestion: "",
      loaded: false
    };
  }

  componentDidMount() {
    this.retrieveQuestions();

    this.setState({
      loaded: true
    });
  }

  onChangeSearchQuestion(e) {
    const searchQuestion = e.target.value;

    this.setState({
      searchQuestion: searchQuestion
    });
  }

  retrieveQuestions() {
    QuestionDataService.getAll()
      .then(response => {
        this.setState({
          questions: response.data
        });
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  refreshList() {
    this.retrieveQuestions();
    this.setState({
      currentQuestion: null,
      currentIndex: -1
    });
  }

  setActiveQuestion(question, index) {
    this.setState({
      currentQuestion: question,
      currentIndex: index
    });
  }

  /*
  removeAllQuestions() {
    QuestionDataService.deleteAll()
      .then(response => {
        console.log(response.data);
        this.refreshList();
      })
      .catch(e => {
        console.log(e);
      });
  }
  */

  searchQuestion() {
    QuestionDataService.findByQuestion(this.state.searchQuestion)
      .then(response => {
        this.setState({
          questions: response.data
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  //have hidden delete all button as not needed
  //if currentQuestion.bolAnswered = true, hide from list
  //search capability was removed during styling
  render() {
    const { questions, currentQuestion, currentIndex } = this.state;
    const nothingToAnswer = questions.every(question => question.answered);
    console.log(this.state)
    return (
      <div id="questions">
      <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h1>
            <span>Expert Area</span>
            <FormattedMessage 
              id="question-list.h1"
              defaultMessage="List of Unanswered Questions"
              description="Question List header"/>
          </h1>
          <h2>
            <Link
              to={"/answers"}
              className="btn btn-secondary"
            >
              View Answered Questions Here
            </Link>
          </h2>
        </div>
      </div>
      <div className="list row form">
        <div className="col-lg-4 questions">
        
          <ul className="list-group">
            {questions &&
              questions.map((question, index) => (
                <div key={index}>
                  {!question.answered &&
                <li
                  className={
                    "list-group-item " +
                    (index === currentIndex ? "active" : "")
                  }
                  onClick={() => this.setActiveQuestion(question, index)}
                  key={index}
                >
                  <p>
                  {question.question}
                  </p> 
                </li>
                }
                </div>
              ))}
          </ul>
        </div>
        <div className="col-lg-8 question-area">
          {currentQuestion ? (
            <div>
              <div>
                <label>
                  <strong>Question:</strong>
                </label>{" "}
                <p>{currentQuestion.question}</p>
              </div>
              <div>
                <label>
                  <strong>Category:</strong>
                </label>{" "}
                {currentQuestion.categoryId === 1 && 
                  <p>Mental Health</p>
                }
                {currentQuestion.categoryId === 2 && 
                  <p>Sexual Health</p>
                }
                {currentQuestion.categoryId === 3 && 
                  <p>Covid-19</p>
                }
                {currentQuestion.categoryId === 4 && 
                  <p>Other</p>
                }
              </div>

              <Link
                to={"/questions/" + currentQuestion.id}
                className="btn btn-secondary"
              >
                Answer this question
              </Link>
            </div>
          ) : (
            <div>
            {this.state.loaded && nothingToAnswer ? (
              <div>
                <p>There are currently no questions to answer. Please check back later.</p>
              </div>
            ) :
            <div>
                <p>Please select a question from the list to see details and provide and answer.</p>
            </div>
            }
            </div>
          )}
        </div>
        </div>
      </div>
      </div>
    );
  }
}

/* Answered status

<div>
                <label>
                  <strong>Status:</strong>
                </label>{" "}
                <p>{currentQuestion.answered ? "Answered" : "Awaiting answer"}</p>
              </div>

*/

/*
Delete all questions button

{this.state.wanttodelete ? (
          <button
            className="m-3 btn btn-sm btn-danger"
            onClick={this.removeAllQuestions}
          >
            Remove All
          </button>
          ) : null }
*/

/*
{nothingToAnswer ? (
            <div>
              <p>There are currently no questions to answer</p>
            </div>
          ) :
          <div>
              <p>Please click on a Question...</p>
          </div>
          }
*/

/*
render() {
    const { questions, currentQuestion, currentIndex } = this.state;
    console.log(this.state)
    return (
      <div id="questions">
      <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h1>
            <span>Expert Area</span>
            <FormattedMessage 
              id="question-list.h1"
              defaultMessage="Question List"
              description="Question List header"/>
          </h1>
        </div>
      </div>
      <div className="list row form">
        <div className="col-lg-4 questions">
        
          <ul className="list-group">
            {questions &&
              questions.map((question, index) => (
                <div key={index}>
                  {!question.answered &&
                <li
                  className={
                    "list-group-item " +
                    (index === currentIndex ? "active" : "")
                  }
                  onClick={() => this.setActiveQuestion(question, index)}
                  key={index}
                >
                  <p>
                  {question.question}
                  </p> 
                </li>
                }
                </div>
              ))}
          </ul>
        </div>
        <div className="col-lg-8 question-area">
          {currentQuestion ? (
            <div>
              <h4>
              <FormattedMessage 
                id="question-list.questionHeader"
                defaultMessage="Question"
                description="Question header"/>
              </h4>
              <div>
                <label>
                  <strong>Question:</strong>
                </label>{" "}
                {currentQuestion.question}
              </div>
              <div>
                <label>
                  <strong>Category:</strong>
                </label>{" "}
                {currentQuestion.categoryId}
              </div>
              <div>
                <label>
                  <strong>Status:</strong>
                </label>{" "}
                {currentQuestion.answered ? "Answered" : "Awaiting answer"}
              </div>

              <Link
                to={"/questions/" + currentQuestion.id}
                className="badge badge-warning"
              >
                Answer this question
              </Link>
            </div>
          ) : (
            <div>
              <p>Please click on a Question...</p>
            </div>
          )}
        </div>
        </div>
      </div>
      </div>
    );
  }
}
*/