import React, { Component } from "react";
import QuestionDataService from "../services/question.service";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Textarea from "react-validation/build/textarea";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const answerLength = value => {
  if (value.length < 50 || value.length > 350) {
    return (
      <div className="alert alert-danger" role="alert">
        The answer must be between 50 and 350 characters.
      </div>
    );
  }
};

export default class Question extends Component {
  constructor(props) {
    super(props);
    this.getQuestion = this.getQuestion.bind(this);
    this.onChangeAnswer = this.onChangeAnswer.bind(this);
    this.updateAnswer = this.updateAnswer.bind(this);
    this.updateAnswered = this.updateAnswered.bind(this);
    this.onChangeCategory = this.onChangeCategory.bind(this);
    //this.deleteQuestion = this.deleteQuestion.bind(this);

    this.state = {
      currentQuestion: {
        id: null,
        userId: null,
        categoryId: null,
        question: "",
        answered: 0,
        answer: null,
        replied: 0,
        createdAt: null,
        updatedAt: null
      },
      message: "",
      successful: false,
      needthisfield: false
    };
  }

  componentDidMount() {
    this.getQuestion(this.props.match.params.id);
  }

  getQuestion(id) {
    QuestionDataService.get(id)
      .then(response => {
        this.setState({
          currentQuestion: response.data
        });
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }
  
  onChangeAnswer(e) {
    const answer = e.target.value
    const answered = 1

    this.setState(function(prevState) {
      return {
        currentQuestion: {
          ...prevState.currentQuestion,
          answer: answer,
          answered: answered
        }
      };
    });
  }

  updateAnswered(status) {
    var data = {
      id: this.state.currentQuestion.id,
      userId: this.state.currentQuestion.userId,
      category: this.state.currentQuestion.categoryId,
      question: this.state.currentQuestion.question,
      //answered: this.state.currentQuestion.answered,
      answer: this.state.currentQuestion.answer,
      replied: this.state.currentQuestion.replied,
      createdAt: this.state.currentQuestion.createdAt,
      updatedAt: this.state.currentQuestion.updatedAt,
      answered: status
    };

    QuestionDataService.update(this.state.currentQuestion.id, data)
      .then(response => {
        this.setState(prevState => ({
          currentQuestion: {
            ...prevState.currentQuestion,
            answered: status
          }
        }));
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  updateAnswer() {
    QuestionDataService.update(
      this.state.currentQuestion.id,
      this.state.currentQuestion
    )
      .then(response => {
        //console.log(response.data);
        this.props.history.push("/questions");
          window.location.reload();
        //this.setState({
            //message: "The question was updated successfully!"
        //});
      })
      .catch(e => {
        console.log(e);
      });
  }

  //below functions not being used

  onChangeCategory(e) {
    const category = e.target.value;
    
    this.setState(prevState => ({
      currentQuestion: {
        ...prevState.currentQuestion,
        category: category
      }
    }));
  }
  
  /*
  deleteQuestion() {    
    QuestionDataService.delete(this.state.currentQuestion.id)
      .then(response => {
        console.log(response.data);
        this.props.history.push('/questions')
      })
      .catch(e => {
        console.log(e);
      });
  }
  */


// there should be a dropdown where the expert can choose a new category (with "other" as option with textbox that appears if checked)
//can I set boolean after component did mount and wrap "no questions" text in that?
  render() {
    const { currentQuestion } = this.state;
    return (
      <div>
        {currentQuestion ? (
          <div id="questions-answer">
          <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1><span>Question</span>{currentQuestion.question}</h1>
            </div>
          </div>
          <div className="row form">
          <div className="edit-form">   

            <Form>
              <div className="form-group">
                <label htmlFor="answer">Your answer:</label>
                <Textarea
                  type="text"
                  rows="4"
                  className="form-control"
                  id="answer"
                  defaultValue={currentQuestion.answer}
                  validations={[required, answerLength]}
                  onChange={this.onChangeAnswer}
                />
              </div>
              <div className="form-group">
                <label>
                  <strong>Category:</strong>
                </label>
                {currentQuestion.categoryId === 1 && 
                  <p>Mental Health</p>
                }
                {currentQuestion.categoryId === 2 && 
                  <p>Sexual Health</p>
                }
                {currentQuestion.categoryId === 3 && 
                  <p>Covid-19</p>
                }
                {currentQuestion.categoryId === 4 && 
                  <p>Other</p>
                }
              </div>
            </Form>

            <button
              type="submit"
              className="button"
              onClick={this.updateAnswer}
            >
              Submit answer
            </button>
            {this.state.message && (
              <div className="form-group">
                <div
                  className={
                    this.state.successful
                      ? "alert alert-success"
                      : "alert alert-danger"
                  }
                  role="alert"
                >
                  {this.state.message}
                </div>
              </div>
            )}

            </div>
          </div>
          </div>
          </div>
        ) : (
          <div>
            <p>Please click on a Question...</p>
          </div>
        )}
      </div>
    );
  }
}

/*
<div className="form-group">
                <label htmlFor="answer">Your answer:</label>
                <Input
                  type="text"
                  className="form-control"
                  id="answer"
                  defaultValue={currentQuestion.answer}
                  validations={[required, answerLength]}
                  onChange={this.onChangeAnswer}
                />
              </div>
*/

/*
Category input

              <div className="form-group">
                <label htmlFor="category">Category</label>
                <Input
                  type="text"
                  className="form-control"
                  id="category"
                  value={currentQuestion.categoryId}
                  onChange={this.onChangeCategory}
                />
              </div>
*/

/* update status button not needed
{this.state.needthisfield ? (
              <button
                className="badge badge-primary mr-2"
                onClick={() => this.updateAnswered(false)}
              >
                Remove Answer
              </button>
            ) : (
              <button
                className="badge badge-primary mr-2"
                onClick={() => this.updateAnswered(true)}
              >
                Update
              </button>

            )}


  delete question button not needed

  { this.state.needthisfield ? (
            <button
              className="badge badge-danger mr-2"
              onClick={this.deleteQuestion}
            >
              Delete
            </button>
            ) : null }
*/