import axios from "axios";

//http://app.nafsii.com/api
export default axios.create({
//Change to localhost:8080 for local or http://app.nafsii.com or live
  baseURL: "http://app.nafsii.com/api",
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*"
  }
});